@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --wave-color: theme('backgroundColor.visual');
}

@layer base {
  /* Styling to meet the previous MUI implementation */

  body {
    line-height: 22px;
  }

  body:has(dialog[open]) {
    overflow: hidden;
  }

  a {
    text-decoration: underline;
  }

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: inline;
    vertical-align: baseline;
  }

  img,
  video {
    height: revert-layer;
  }
  .skeleton {
    @apply bg-action-subtle animate-pulse;
  }
}

@layer components {
  /* Styling for the rich-text component */
  .rich-text--component {
    &
      :where(p):not(
        :where([class~='not-rich-text--component'], [class~='not-rich-text--component'] *)
      ) {
      margin-top: 1.25em;
      margin-bottom: 1.25em;

      &:empty {
        display: none;
      }
    }

    & :where(h1) {
      @apply mb-1.5;
    }

    & :where(h2) {
      @apply mb-1 mt-4;
    }

    & :where(h3) {
      @apply mb-0.5 mt-3;
    }

    & :where(h4) {
      @apply mb-0.25 mt-2;
    }

    & :where(h2 + *, h3 + *, h4 + *) {
      margin-top: 0;
    }

    &
      :where(ul, ol):not(
        :where([class~='not-rich-text--component'], [class~='not-rich-text--component'] *)
      ) {
      margin-top: 1.25em;
      margin-bottom: 1.25em;
      margin-inline-start: 1.625em;

      > li {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        padding-inline-start: 0.375em;

        p {
          margin-top: 0.75em;
          margin-bottom: 0.75em;
        }
      }
    }

    & :where(blockquote) {
      @apply text-default border-s-default border-s-4 font-bold italic;
      margin-top: 1.6em;
      margin-bottom: 1.6em;
      padding-inline-start: 1em;
      quotes: '\201C' '\201D' '\2018' '\2019';

      p:first-of-type::before {
        content: open-quote;
      }

      p:last-of-type::after {
        content: close-quote;
      }
    }

    & :where(table) {
      width: 100%;
      table-layout: auto;
      text-align: left;
      margin-top: 2em;
      margin-bottom: 2em;

      :where(td p, th p) {
        margin-top: 0.75em;
        margin-bottom: 0.75em;
      }

      th {
        @apply font-bold;
      }

      tbody tr {
        @apply border-default border-b;

        &:last-child {
          @apply border-0;
        }
      }
    }

    & :where(img) {
      margin-top: 2em;
      margin-bottom: 2em;
    }

    & :where(picture) {
      margin-top: 2em;
      margin-bottom: 2em;

      > img {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    & :where(figure) {
      margin-top: 2em;
      margin-bottom: 2em;

      > * {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    & :where(figcaption) {
      margin-top: 0.75em;
    }

    & :where(hr) {
      margin-top: 3em;
      margin-bottom: 3em;
    }

    & > :first-child {
      margin-top: 0;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }
}

@layer utilities {
  .hide-scrollbar {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
}

/* This was not possible set on a component basis */
[data-radix-popper-content-wrapper] {
  position: absolute !important;
}
